
import {computed, defineComponent, h, ref, watch} from "vue";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonItemDivider,
  IonList,
  IonPage,
  IonRow,
  IonSearchbar,
  isPlatform,
  onIonViewWillEnter,
} from "@ionic/vue";
import {useStore} from "vuex";
import {Material} from "@/domain/model/material/material.model";
import _, {map} from "lodash";
import StockItemListComponent from "@/app/view/stock/list/StockItemListComponent.vue";
import {useStockApi} from "@/domain/service/api/stock.api";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {NButton, NDataTable, NSpace, NTag} from "naive-ui";
import {Stock} from "@/domain/model/stock/stock.model";
import {useStock} from "@/domain/service/model/stock.service";
import {useRouter} from "vue-router";
import {Order} from "@/domain/model/order/order.model";

export default defineComponent({
  name: "StockList",
  components: {
    IonItemDivider,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonList,
    IonSearchbar,
    StockItemListComponent,
    NSpace,
    NDataTable,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const isTablet = ref<boolean>(!isPlatform('desktop'));
    const allActiveOrders = computed<Order[]>(() => store.getters.allActiveOrders);
    const searchWord = ref('');
    const searchMaterial = (material: Material, word: string): boolean => material.ref.toLowerCase().includes(word.toLowerCase()) || material.name.toLowerCase().includes(word.toLowerCase());
    const filterMaterial = (materials: Material[], word: string): Material[] => _.filter(materials, (material: Material) => searchMaterial(material, word));
    const sortMaterial = (materials: Material[]): Material[] => _.sortBy(materials, (material: Material) => material.ref.toLowerCase());
    const justTypeMaterial = (materials: Material[]): Material[] => _.filter(materials, (material: Material) => material.type === 'part');
    const materials = computed<Material[]>(() => sortMaterial(filterMaterial(justTypeMaterial(store.getters.allMaterial), searchWord.value)));

    const factoryStock = (materialRef: string): Stock => store.getters.lastStockByRef(materialRef);
    const blockStock = (materialRef: string): number =>  useStock().calculateBlockStock(allActiveOrders.value, materialRef);
    const availableStock = (materialRef: string): number => {
      if(!factoryStock(materialRef)){
        return 0;
      }
      return Number((Number(factoryStock(materialRef).amount) - Number(blockStock(materialRef))).toFixed(2));
    };
    const actualStatus = (materialRef: string): boolean => availableStock(materialRef) >= 0;

    const data = ref<any>([]);
    const replaceData = () => {
      data.value =
          map(materials.value, (material: Material) => {
            return {
              id: material.id,
              ref: material.ref,
              factoryStock: factoryStock(material.ref).amount,
              blockStock: blockStock(material.ref),
              availableStock: availableStock(material.ref),
              state: actualStatus(material.ref),
            };
          });
    };

    const isEmpty = computed(() => {
      return store.getters.allMaterial.length === 0;
    });

    const goMaterialStockRecord = (materialId: string) => {
      router.push({
        name: 'StockItemRecordPage',
        params: {id: materialId},
      });
    };

    watch(materials, () => replaceData(), {immediate: true});

    onIonViewWillEnter(() => {
      useMaterialApi().fetchAll();
      useStockApi().fetchAll();
    });

    return {
      data,
      columns: [
        {
          title: "Referencia",
          width: '300px',
          key: "ref",
          sorter: 'default'
        },
        {
          title: "Stock en fábrica",
          width: '300px',
          key: "factoryStock",
          sorter: 'default'
        },
        {
          title: "Stock reservado",
          width: '300px',
          key: "blockStock",
          sorter: 'default'
        },
        {
          title: "Stock disponible",
          width: '300px',
          key: "availableStock",
          sorter: 'default'
        },
        {
          title: "Estado",
          width: '250px',
          key: "state",
          sorter: (row1: any, row2: any) => {
            if(Number(actualStatus(row1.ref)) > Number(actualStatus(row2.ref)))
              return 1;
            if(Number(actualStatus(row1.ref)) < Number(actualStatus(row2.ref)))
              return -1;
            else
              return 0;
          },
          render(row: any): any {
            return h(
                NTag,
                {
                  type: actualStatus(row.ref) ? 'success' : 'error',
                },
                {default: () => 'Stock'},
            );
          },
        },
        {
          title: 'Acciones',
          key: 'actions',
          width: '350px',
          render(row: any): any {
            return h(
                NButton,
                {
                  size: 'small',
                  onClick: () => goMaterialStockRecord(row.id),
                },
                {default: () => 'Detalles'},
            );
          },
        },
      ],
      isTablet,
      searchWord,
      isEmpty,
      materials
    };
  }
})
