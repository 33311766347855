import {Order} from "@/domain/model/order/order.model";
import _ from "lodash";
import {Component} from "@/domain/model/material/material.model";
import {useStore} from "vuex";
import {Stock, StockRecord} from "@/domain/model/stock/stock.model";
import {uuid4} from "@capacitor/core/dist/esm/util";
import memoize from 'timed-memoize';

const calculatePending = (order: Order): number => {
    // return Number(order.expectedAmount) -
    //     order.producedOrder.reduce((accumulator: number, itemB: ProducedOrder) => accumulator + Number(itemB.amount), 0);
    if(order.state === 'finalized')
        return 0;
    if (order.state === 'pending')
        return order.expectedAmount
    else
        return order.pendingAmount;
}

const calculateBlockStock = (orders: Order[], ref: string): number => {
    let accumulated = 0;
    orders.forEach((orderNode: Order) => {
        if (!orderNode)
            return;

        const amounts: Component[] = _.filter(orderNode.material.components,
            (componentNode: Component) => componentNode.ref === ref);
        accumulated += amounts.reduce((accumulator: number, itemB: Component) => accumulator + (Number(itemB.amount) * calculatePending(orderNode)), 0);
    });

    return accumulated;
}
const existLackOfMaterialInKit = (orders: Order[], ref: string): boolean => {
    const store = useStore();
    const ordersAmounts: boolean[] = [];
    orders.forEach((orderNode: Order) => {
        const materialAmountCondition = _.some(orderNode.material.components, (componentNode: Component) => {
            const stock: Stock = store.getters.lastStockByRef(componentNode.ref);
            return (Number(stock.amount) - calculateBlockStock(orders, componentNode.ref)) < 0;
        })
        ordersAmounts.push(materialAmountCondition);
    })
    return _.some(ordersAmounts, (item: boolean) => item)
}
const calculateAvailableStock = (orders: Order[], ref: string): number => {
    const store = useStore();
    const materialBlockStock: number = calculateBlockStock(orders, ref);
    const materialFactoryStock: Stock = store.getters.lastStockByRef(ref);

    if (!materialFactoryStock)
        return -materialBlockStock;

    return Number(materialFactoryStock.amount) - materialBlockStock;
};

const emptyStockRecord = () => {
    const emptyStockRecord: StockRecord = {
        id: uuid4(),
        createdAt: + new Date(),
        amount: 0,
        concept: '',
        metaData: [],
    }
    return emptyStockRecord;
};

const calculateAvailableStockMemoized = memoize(calculateAvailableStock, {
    timeout: 3000,
});

export function useStock() {
    return {
        calculatePending,
        calculateBlockStock,
        calculateAvailableStock,
        existLackOfMaterialInKit,
        emptyStockRecord,
        calculateAvailableStockMemoized
    };
}