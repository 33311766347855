import store from '@/domain/store';
import {http} from "@/domain/service/api/http-api";
import _ from "lodash";
import {Stock, StockRecord, StockTimeline} from "@/domain/model/stock/stock.model";
import {formatDate} from "@/domain/helper/date.helper";
import {Material} from "@/domain/model/material/material.model";


export const sanetizeStock = (stockRecord: StockRecord): any => {
    const newStockRecord: any = _.cloneDeep(stockRecord);
    // fetchTimeLine()
    // newStockRecord.stockId =
    return newStockRecord;
};


export function useStockApi() {
    const fetchAll = async () => {
        const response = await http().get('stock/');
        const allStocks = _.cloneDeep(response.data);
        store.commit('saveAllStock', allStocks);
    };

    const fetchTimeline = async (ref: string) => {
        const response = await http().get('stock/timeline/' + ref);
        const serverTimeline: any = _.cloneDeep(response.data);
        const stockTimeline: StockTimeline = {
            ref: serverTimeline.ref,
            timeline: serverTimeline.timeline
        };
        store.commit('saveStockTimeline', stockTimeline);
    };

    const updateStockTimeline = async (stockRecord: StockRecord, stockId: string) => {
        const newStockRecord: any = _.cloneDeep(stockRecord);
        newStockRecord.stockId = stockId;
        await http().post('stock/update', newStockRecord);
        await fetchAll();
    };

    return {fetchAll, fetchTimeline, updateStockTimeline};

}