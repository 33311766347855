
import {
  IonBadge,
  IonButton,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel, IonNote,
  IonRow,
  isPlatform
} from "@ionic/vue";
import {computed, defineComponent, ref} from "vue";
import {Material} from "@/domain/model/material/material.model";
import {useStore} from "vuex";
import {Stock} from "@/domain/model/stock/stock.model";
import router from "@/app/router";
import {useRouter} from "vue-router";
import {useStock} from "@/domain/service/model/stock.service";
import {alertCircleOutline, arrowDownOutline, arrowUpOutline} from "ionicons/icons";

export default defineComponent({
  name: "StockListItemComponent",
  components: {
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonBadge,
    IonButton,
    IonGrid,
    // IonChip,
    // IonIcon,
  },
  props: {
    material: null,
  },
  setup(prop: any) {
    const materialRef = ref<Material>(prop.material);
    const store = useStore();
    const router = useRouter();
    const {calculateBlockStock} = useStock();
    const isTablet = !isPlatform('desktop');


    const materialTotalStock = computed<Stock>(() => store.getters.lastStockByRef(materialRef.value.ref));

    const factoryStock = computed<Stock>(() => store.getters.lastStockByRef(materialRef.value.ref));

    const blockStock = computed<number>(() => {
      return calculateBlockStock(store.getters.allActiveOrders, materialRef.value.ref);
    });

    const availableStock = computed<number>(() => {
      if(!factoryStock.value){
        return 0;
      }
      return Number((Number(factoryStock.value.amount) - Number(blockStock.value)).toFixed(2));
    });

    const actualStatus = computed<boolean>(() => availableStock.value >= 0);

    const goMaterialStockRecord = () => {
      router.push({
        name: 'StockItemRecordPage',
        params: {id: materialRef.value.id},
      });
    };


    return {
      arrowUpOutline,
      arrowDownOutline,
      alertCircleOutline,
      isTablet,
      materialRef,
      materialTotalStock,
      factoryStock,
      blockStock,
      availableStock,
      actualStatus,
      goMaterialStockRecord,
    };
  }
})
